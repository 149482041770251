import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { decodeHtmlEntity, getAttr } from '../helper/helper'
import styled from '@emotion/styled'

const PageWrapper = styled('section')`
  h1.title {
    font-family: 'Roboto', sans-serif;
    font-size: 49px;
    font-weight: 500;
    color: #3a3a3a;
  }
  .content {
    & > h2,
    & > h3 {
      color: #3a3a3a;
      font-weight: 500;
      font-family: 'Roboto', sans-serif;
    }
    & > p {
      font-family: 'Roboto', sans-serif;
      color: #3a3a3a;
      font-size: 18px;
    }
  }
  // Deals
  .deals-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    margin: 0 -1%;
  }

  .deals-elem-wrapper {
    height: 100%;
  }

  .deals-elem {
    width: 31.33%;
    box-shadow: 3px 2px 6px 1px #e9e9e9;
    border: 1px solid #e9e9e9;
    margin: 1%;

    a {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .top-part {
      height: 100%;
      line-height: 0;
      overflow: hidden;

      img {
        transition: 0.5s linear;
        width: 100%;
        height: 300px;
        object-fit: cover;
      }
    }

    .bottom-part {
      padding: 15px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .date {
      color: #a18c78;
      margin: 0;
    }

    .title {
      margin: 15px 0;
      text-transform: uppercase;
    }

    .description {
      margin: 0 0 15px;
    }

    button {
      transition: 0.2s linear;
      padding-top: 10px;
      margin: auto auto 0 0;
      cursor: pointer;
      text-transform: uppercase;
      border: none;
      border: 2px solid #a18c78;
      background: #a18c78;
      color: #fff;
      padding: 9px 20px 7px;
      border-radius: 8px;
      text-align: center;
    }

    a {
      &:hover {
        .top-part {
          img {
            transform: scale(1.3);
          }
        }
        button {
          background: #fff;
          color: #a18c78;
        }
      }
    }
  }

  @media (max-width: 991px) {
    h1.title {
      font-size: 45px;
      margin: 0 0 10px;
    }

    .deals-elem {
      width: 45.33%;
      margin: 2%;
    }
  }

  @media (max-width: 640px) {
    h1.title {
      font-size: 35px;
    }

    .deals-container {
      margin: 0;
    }

    .deals-elem-wrapper {
      height: auto;
    }

    .deals-elem {
      width: 100%;
      margin: 2% 0 5%;

      .top-part {
        img {
          height: 200px;
        }
      }
    }
  }
`

export const PageTemplate = ({ title, content, acf_content }) => {
  const pageBuilderContentArray = acf_content.pageBuilder
    ? Array.isArray(acf_content.pageBuilder)
      ? acf_content.pageBuilder.length
        ? Object.keys(acf_content.pageBuilder[0]).length
          ? acf_content.pageBuilder
          : null
        : null
      : null
    : null
  let pageContentHTML

  if (pageBuilderContentArray) {
    pageContentHTML = pageBuilderContentArray
      .map(({ contentBlock }) => contentBlock)
      .join('')

    const dealsSC = pageContentHTML.match(/\[\s*deal[^\]]*\]/gi) || []

    // check if it's a deals page
    if (dealsSC.length) {
      // sanitizing deals
      pageContentHTML = decodeHtmlEntity(pageContentHTML).replace(
        /\[\s*deal[^\]]*\]/gi,
        ''
      )
      // iterating over each deal
      pageContentHTML += `<div class="deals-container">
      ${dealsSC
        .sort((dateA, dateB) => {
          const a = new Date(getAttr(dateA, 'date'))
          const b = new Date(getAttr(dateB, 'date'))

          return a > b ? -1 : 1
        })
        .map((deal) => {
          deal = decodeHtmlEntity(deal)
          // getting attributes
          let date = getAttr(deal, 'date')
          const title = getAttr(deal, 'title')
          const link = getAttr(deal, 'link')
          const image = getAttr(deal, 'image')
          const content = getAttr(deal, 'content')
          const btn_text = getAttr(deal, 'button_text')

          // formatting date
          const dateOptions = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }

          date = new Date(date).toLocaleString('en-US', dateOptions).slice(5)

          return `
            <div class="deals-elem">
              <div class="deals-elem-wrapper">
                <a href="${link}" title="${title}" target="_blank">
                  <div class="top-part">
                    <img src="${image}">
                  </div>
                  <div class="bottom-part">
                    <p class="date">${date}</p>
                    <h3 class="title">${title}</h3>
                    ${content ? `<p class="description">${content}</p>` : ''}
                    <button type="button">${btn_text}</button>
                  </div>
                </a>
              </div>
            </div>`
        })
        .join('')}
        </div>`
    }
  } else {
    pageContentHTML = content
  }

  return (
    <PageWrapper className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h1 className="title">{title}</h1>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: pageContentHTML }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

const Page = ({ data }) => {
  const { wpPage: page } = data
  if (page.content !== undefined) {
    return (
      <Layout>
        <PageTemplate
          title={page.title}
          content={page.content}
          acf_content={page.acf_content}
        />
      </Layout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      acf_content {
        pageBuilder {
          ... on WpContentNode_AcfContent_PageBuilder_ContentBlock {
            contentBlock
            fieldGroupName
          }
        }
      }
    }
  }
`
